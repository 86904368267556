


@media only screen and ( max-width: 480px ) {

      .footer-bottom ul li::after { 
            content: "•"; 
            color: #707070; 
            padding-left: 10px; 
            margin-right: 10px; 
      } 

      /*
      * Fiter
      */
      .top-filter .box-top-filter  {
            flex-direction: column;
      }

      .box-top-filter .box-top-filter-item{
            width: 100%;
      }
      
      .box-top-filter .icons-search {
            flex-direction: column;
            display: flex;
            align-items: center;
            margin: 0 auto;
            margin-bottom: 15px;
            border-radius: 5px;
      }


      .filter-bottom  , .filter-bottom .box-bottom-filter {
            flex-direction: column;
      }

      .filter-bottom .box-bottom-filter, .filter-bottom .box-bottom-filter-item {
            width: 100%;
      }

      /*
      * Deals Banner
      */

      .deal-filter {
            margin-top: 0;
      }

      .deals-banner div.info > div  h3 { 
            color: #fff;
            display: block; 
            margin: 0 auto; 
            font-size: 26px;  
      }
      

      .deals-results-content .box-deals-item {
            flex-direction: column;
      }

      .box-deals-item .box-deals-item-body {
            width: 100%;
      }

      .box-deals-item .box-item-price {
            width: 100%;
            max-width: 100%;
      }

      .deals-results-content .box-deals-item .box-img {
            height: 100%;
      }

       /*
      * page deals detail
      */

      .deals-detail-back-to {
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
      }

      .deals-details-left.p-0, .deals-details-right .p-4, .rooms-time .pricing-room-category.px-4, .pricing-room-content .table tr th.px-4{
            padding-right: calc(var(--bs-gutter-x) * .5) !important;
            padding-left: calc(var(--bs-gutter-x) * .5) !important;
      }

      

      .pricing-room-content .table tr td {
            padding: 1rem 0.3rem;
      }

      .overview {
            padding: 0;
      }

      .overview-know {
            padding-right: calc(var(--bs-gutter-x) * .5);
            padding-left: calc(var(--bs-gutter-x) * .5);
      }

      .overview .overview-history {
            flex-direction: column;
      }
      

      .overview-history .history-timeline {
            padding: calc(var(--bs-gutter-x) * .5) !important;
      }

      .history-timeline .timeline-event .timeline-event-content {
            left: 55px;
            width: calc(100% - 30px);
      }

      /*
      *  deals rooms
      */
      .deals-rooms-info .rooms-daterange {
            font-size: 20px;
      }

      .deals-rooms-setps ul {
            display: block;
      }

      .deals-rooms-setps ul li {
            display: inline-block;
      }

      .deals-rooms-left .p-4, .deals-rooms-right .p-4{
            padding-right: calc(var(--bs-gutter-x) * .5) !important;
            padding-left: calc(var(--bs-gutter-x) * .5) !important;
      }

      
}


@media only screen and ( max-width: 768px ) {

      .filter-bottom  , .filter-bottom .box-bottom-filter {
            flex-direction: column;
      }

      .filter-bottom .box-bottom-filter {
            width: 100%;
      }

      .deals-results-content .box-deals-item {
            flex-direction: column;
      }

      .box-deals-item .box-deals-item-body, .box-deals-item .box-img {
            width: 100%;
      }

      .box-deals-item .box-item-price {
            width: 100%;
            max-width: 100%;
      }

      .deals-results-content .box-deals-item .box-img {
            height: 100%;
            width: 100%;
            text-align: center;
      }

     .box-item-price .box-item-price-right {
            text-align: right;
            float: right;
      }

     .box-item-price .box-item-price-right .view-detail {
           display: inline-block !important;
      }
      
      /*
      * page deals detail
      */

      .deals-details .deals-details-left, .deals-details .deals-details-right {
            margin-bottom: 1.5rem;
      }

      .pricing-room-content .table tr td {
            padding: 1rem 0.3rem;
      }

      .overview .overview-history {
            flex-direction: column;
      }

      .overview-history .history-timeline {
            margin: 0;
      }

      .history-timeline .timeline-event .timeline-event-content {
            left: 55px;
            width: calc(100% - 30px);
      }

      .our-staterooms div.info{
            margin: 10px;
      }

      .home-destinations-sliding-banner .slick-list , .our-staterooms-sliding-banner .slick-list  {
            min-height: 191px;
      }

      .rooms-value-add-item .d-flex {
            flex-direction: column;
      }

      .rooms-value-add-item .box-img {
            width: 100%;
            margin: 0 auto;
            text-align: center;
      }

      .deals-rooms-value-add-pick .rooms-value-add-item .rooms-info{
            padding: 1.5rem 0;
      }

      .deals-rooms-value-add-pick .rooms-info .rooom-value-add {
            flex-direction: column;
            width: 100%;
      }

      .deals-rooms-value-add-pick .rooms-info .rooms-value-add-detail {
            margin-right: 0;
            margin-top: 15px;
      }

      .deals-rooms-value-add-pick .rooms-info .rooms-value-add-price {
            width: 100%;
            text-align: center !important;
      }

      .modal-thanks .modal-dialog {
            padding: 2rem;
      }

      .modal-thanks .btn-action {
            margin-bottom: 15px;
            margin-right: 0;
      }
      .modal-dialog, .modal-dialog.cardholder {
            padding: 0 !important;
      }

      .modal-thanks .modal-dialog  .modal-content, .modal-dialog.cardholder .modal-content {
            width: 100% !important;
      }
      .modal-thanks .modal-body, .modal-flash-sale .modal-body {
            padding: 2rem !important;
      }

      .modal-thanks .modal-dialog {
            padding: auto 2rem;
      }

      .modal-thanks .btn-action {
            margin-bottom: 15px;
            margin-right: 0;
      }

      .modal-flash-sale.show {
            display: flex !important;
            align-items: center;
            justify-content: center;
      }

      .deals-detail-back-to {
            padding-left: 15px;
      }

}

@media only screen and ( max-width: 990px ) {
	.isDesktop { 
            display: none;
      }

	.isMobile { 
            display: block; 
      }

      /*
      * Filter
      */
      .top-filter .box-top-filter  {
            flex-direction: column;
      }
      
      .box-top-filter .box-top-filter-item:first-child {
            border-top-right-radius: 5px;
            border-bottom-left-radius: 0 !important;
      }

      .box-top-filter .box-top-filter-item{
            border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
            width: 100%;
      }

      .box-top-filter .box-top-filter-item {
            border-bottom: none !important;
      }
      
      .box-top-filter .icons-search {
            flex-direction: column;
            display: flex;
            align-items: center;
            margin: 0 auto;
            /* margin-bottom: 15px; */
            border-top-right-radius: 0 !important;
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            width: 100%;
            border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
      }

       /*
      * page deals detail
      */

      .deals-details .deals-details-left, .deals-details .deals-details-right {
            width: 100%;
      }

      .deals-details .deals-details-left, .deals-details .deals-details-right {
            margin-bottom: 1.5rem;
      }

      .overview .overview-history {
            flex-direction: column;
            
      }

      .overview-history .history-timeline {
            width: 100%;
      }

      .history-timeline .timeline-event .timeline-event-content {
            left: 55px;
            width: calc(100% - 30px);
      }

      /*
      *  deals rooms
      */
      .deals-rooms-left{
            margin-bottom: calc(var(--bs-gutter-x) * .5);
      }

      .deals-rooms .deals-rooms-left, .deals-rooms .deals-rooms-right{
            width: 100%;
      }

      .deals-rooms-setps ul {
            display: block !important;
      }

      .deals-rooms-setps ul li {
            display: inline-block;
      }

      .number-of-rooms-info {
            width: calc(100% - 175px);
      }
}


@media screen and (min-width: 768px) { 
      .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
      }
}
@media (min-width: 992px) {
      .modal-lg, .modal-xl {
            --bs-modal-width: 600px;
      }
      .promo-room-1 .modal-lg, .modal-xl {
            --bs-modal-width: 800px;
      }
}
@media only screen and ( max-width: 991px ) and ( min-width: 768px ) {
      .deals-results-content .box-deals-item {
            flex-direction: column;
      }

      .box-deals-item .box-deals-item-body, .box-deals-item .box-img {
            width: 100%;
      }

      .box-deals-item .box-item-price {
            width: 100%;
            max-width: 100%;
      }

      .deals-results-content .box-deals-item .box-img {
            height: 100%;
            width: 100%;
            text-align: center;
      }

     .box-item-price .box-item-price-right {
            text-align: right;
            float: right;
      }

     .box-item-price .box-item-price-right .view-detail {
           display: inline-block !important;
      }
    
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {

	h1 { 
            font-size: 28px;
      }

	h2 { 
            font-size: 24px;
      }

	h3 { 
            font-size: 22px;
      }

	h4 { 
            font-size: 19px;
      }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {

	h1 { 
            font-size: 25px; 
      }

	h2 { 
            font-size: 22px;
      }

	h3 { 
            font-size: 18px;
      }

	h4 { 
            font-size: 16px;
      }   

}

@media only screen and ( max-width: 1366px ) and ( min-width: 991px ) {
	.mainNavBar > div > div.bd-highlight.child-menu:last-child a {
            padding-left: 20px;
            padding-right: 20px;
      }
     
}

@media only screen and ( max-width: 1366px ) and ( min-width: 1024px ) {

      /*
      * Filter
      */
      .box-top-filter .box-top-filter-item, .box-top-filter .icons-search{
            padding-right: 1.5rem!important;
            padding-left: 1.5rem!important;
      }

      .rooms-value-add-item .d-flex {
            flex-direction: column;
      }

      .rooms-value-add-item .box-img {
            width: 100%;
            margin: 0 auto;
            text-align: center;
      }

      .deals-rooms-value-add-pick .rooms-value-add-item .rooms-info{
            padding: 1.5rem 0;
      }

      .deals-rooms-value-add-pick .rooms-info .rooom-value-add {
            flex-direction: column;
            width: 100%;
      }

      .deals-rooms-value-add-pick .rooms-info .rooms-value-add-detail {
            margin-right: 0;
            margin-top: 15px;
      }

      .deals-rooms-value-add-pick .rooms-info .rooms-value-add-price {
            width: 100%;
            text-align: center !important;
      }

}