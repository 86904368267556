/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/** Swiper use */
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

/* Import ngx-sharebuttons global styles */
@import '../node_modules/ngx-sharebuttons/themes/default';
/* Import ngx-sharebuttons modern theme styles */
@import '../node_modules/ngx-sharebuttons/themes/modern';

/** bootstrap */
@import "../src/assets/styles/bootstrap.min.css";
@import "../src//assets/styles/media.css";

/** lightgallery */
@import '~lightgallery/css/lightgallery.css';
@import '~lightgallery/css/lg-thumbnail.css';
@import '~lightgallery/css/lg-zoom.css';

/** Fonts */
@font-face {
    font-family: 'Avenir Next Bold'; //This is what we are going to call
    src: url('../src/assets/fonts/AvenirNext-Bold.ttf');
}
@font-face {   
    font-family: 'Avenir Next';  //This is what we are going to call  
    src: url('./assets/fonts/AvenirNext-Regular.ttf');
}
@font-face {   
    font-family: 'Avenir Next Demi';  //This is what we are going to call  
    src: url('./assets/fonts/AvenirNext-DemiBold.ttf');
}
@font-face {   
    font-family: 'Avenir Next Light';  //This is what we are going to call  
    src: url('./assets/fonts/AvenirNext-UltraLight.ttf');
}
@font-face {   
    font-family: 'Avenir Next Medium';  //This is what we are going to call  
    src: url('./assets/fonts/AvenirNext-Medium.ttf');
}
@font-face {   
    font-family: 'Avenir Next Italic';  //This is what we are going to call  
    src: url('./assets/fonts/AvenirNext-Italic.ttf');
}
@font-face {   
    font-family: 'Avenir Next Heavy';  //This is what we are going to call  
    src: url('./assets/fonts/AvenirNext-Heavy.ttf');
}
@font-face {   
    font-family: 'High Summit';  //This is what we are going to call  
    src: url('./assets/fonts/HighSummit.ttf');
}

ion-toast.custom-toast {
    --background: #fff;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #4b4a50;
    align-items: end;
}
ion-toast.custom-toasts {
    --background: #fff;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #4b4a50;
    align-items: end;
    font-size: larger !important;
}
::ng-deep select {
    option {
      background-color: #fff;
      color: #D7182A;
    }
}
.loader-css-class {
    --background: #fff;
    --spinner-color:#D7182A;
 }