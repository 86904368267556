/*icomoon CSS*/

@font-face {
    font-family: "icomoon";
  src:  url('fonts/icomoon.eot?wodyul#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?wodyul') format('truetype'),
    url('fonts/icomoon.woff?wodyul') format('woff'),
    url('fonts/icomoon.svg?wodyul#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="flipbook-icon-"], [class*=" flipbook-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flipbook-icon-pause1:before {
  content: "\e911";
}
.flipbook-icon-play_arrow:before {
  content: "\e900";
}
.flipbook-icon-volume_mute:before {
  content: "\e912";
}
.flipbook-icon-volume_up:before {
  content: "\e913";
}
.flipbook-icon-add:before {
  content: "\e90e";
}
.flipbook-icon-clear:before {
  content: "\e916";
}
.flipbook-icon-remove1:before {
  content: "\e910";
}
.flipbook-icon-text_format:before {
  content: "\e901";
}
.flipbook-icon-insert_drive_file:before {
  content: "\e902";
}
.flipbook-icon-file_download:before {
  content: "\e903";
}
.flipbook-icon-keyboard_arrow_down:before {
  content: "\e918";
}
.flipbook-icon-keyboard_arrow_left:before {
  content: "\e904";
}
.flipbook-icon-keyboard_arrow_right:before {
  content: "\e905";
}
.flipbook-icon-picture_as_pdf:before {
  content: "\e914";
}
.flipbook-icon-local_printshop:before {
  content: "\e906";
}
.flipbook-icon-zoom_out_map:before {
  content: "\e907";
}
.flipbook-icon-fullscreen:before {
  content: "\e908";
}
.flipbook-icon-fullscreen_exit:before {
  content: "\e909";
}
.flipbook-icon-first_page:before {
  content: "\e90a";
}
.flipbook-icon-last_page:before {
  content: "\e90b";
}
.flipbook-icon-share1:before {
  content: "\e90c";
}
.flipbook-icon-bookmark1:before {
  content: "\e917";
}
.flipbook-icon-search1:before {
  content: "\e90d";
}
.flipbook-icon-toc:before {
  content: "\e915";
}
.flipbook-icon-view_module:before {
  content: "\e90f";
}
.flipbook-icon-plus:before {
  content: "\f067";
}
.flipbook-icon-minus:before {
  content: "\f068";
}
.flipbook-icon-search:before {
  content: "\f002";
}
.flipbook-icon-th-large:before {
  content: "\f009";
}
.flipbook-icon-close:before {
  content: "\f00d";
}
.flipbook-icon-remove:before {
  content: "\f00d";
}
.flipbook-icon-times:before {
  content: "\f00d";
}
.flipbook-icon-download:before {
  content: "\f019";
}
.flipbook-icon-volume-off:before {
  content: "\f026";
}
.flipbook-icon-volume-up:before {
  content: "\f028";
}
.flipbook-icon-bookmark:before {
  content: "\f02e";
}
.flipbook-icon-print:before {
  content: "\f02f";
}
.flipbook-icon-play:before {
  content: "\f04b";
}
.flipbook-icon-pause:before {
  content: "\f04c";
}
.flipbook-icon-expand:before {
  content: "\f065";
}
.flipbook-icon-compress:before {
  content: "\f066";
}
.flipbook-icon-twitter:before {
  content: "\f099";
}
.flipbook-icon-facebook:before {
  content: "\f09a";
}
.flipbook-icon-facebook-f:before {
  content: "\f09a";
}
.flipbook-icon-list-ol:before {
  content: "\f0cb";
}
.flipbook-icon-envelope:before {
  content: "\f0e0";
}
.flipbook-icon-linkedin:before {
  content: "\f0e1";
}
.flipbook-icon-angle-double-left:before {
  content: "\f100";
}
.flipbook-icon-angle-double-right:before {
  content: "\f101";
}
.flipbook-icon-angle-left:before {
  content: "\f104";
}
.flipbook-icon-angle-right:before {
  content: "\f105";
}
.flipbook-icon-angle-down:before {
  content: "\f107";
}
.flipbook-icon-file:before {
  content: "\f15b";
}
.flipbook-icon-digg:before {
  content: "\f1a6";
}
.flipbook-icon-share-alt:before {
  content: "\f1e0";
}
.flipbook-icon-pinterest-p:before {
  content: "\f231";
}
.flipbook-icon-whatsapp:before {
  content: "\f232";
}
.flipbook-icon-mouse-pointer:before {
  content: "\f245";
}
.flipbook-icon-i-cursor:before {
  content: "\f246";
}
.flipbook-icon-reddit-alien:before {
  content: "\f281";
}


/*icomoon CSS end*/

.flipbook-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('../../assets/styles/images/overlay_lightbox.png') repeat;
    z-index: 2147483646 !important;
}

.flipbook-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    text-shadow: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
    /*background: url('../images/overlay_lightbox.png') repeat;*/
}

.flipbook-lightbox-thumb-overlay {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.2);
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.flipbook-lightbox-thumb-overlay:hover {
    opacity: 1;
}

.flipbook-lightbox-thumb-icon {
    position: relative;
    margin-left: -50%;
    margin-top: -50%;
    color: #555;
    display: none;
}

.flipbook-lightbox-thumb-icon-holder {
    position: absolute;
    left: 50%;
    top: 50%;
}

.flipbook-wrapper-transparent {
    width: 100%;
    height: 100%;
    background: none;
}

.flipbook-main-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    direction: ltr !important;
}

.flipbook-bookLayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.flipbook-zoomLayer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    overflow: hidden;
    opacity: .7;
    /*opacity: .5;*/
    /*ointer-events:none;*/
}

.flipbook-zoomLayer-inside {
    display: block;
    position: relative;
    /* background-image: url("../grid.jpg"); */
    background-size: contain;
    text-align: center;
}

.flipbook-zoomLayer-inside .flipbook-carousel-page {
    float: left;
}

.flipbook-center-container {
/*    transform: translateZ(0px);
    -webkit-transform: translateZ(0px);*/
/*    -backface-visibility: hidden;
    -webkit-backface-visibility: hidden;*/
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.flipbook-center-container-wrapper {}

.flipbook-center-wrapper {}

.flipbook-book {
    display: block;
    position: relative;
}

.flipbook-page {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateZ(0px);
    /*-webkit-transform:translateZ(0px);*/
    will-change: transform;
}

.flipbook-page3 {
    position: absolute;
    background: #eee;
    /* overflow: hidden; */
    /*transform: translateZ(0px);*/
    /*-webkit-transform:translateZ(0px);*/
    /*will-change: transform;*/
}

.flipbook-page3-html {
    position: absolute;
    /* width: 100%; */
    /* height: 100%; */
    height: 1000px;
    /* width: 707px; */
    transform-origin: 0 0;
    top: 0;
    left: 0;
    /* transform-origin:0 0; */
    /* -webkit-transform-origin:0 0; */
    /*transform:translateZ(0);*/
    /* -webkit-transform:translateZ(0); */
    /* float:  left; */
    /* z-index: 9999999999; */
}

.flipbook-page3-inner {
    overflow:hidden;
    width: 100%;
    height: 100%;
    position: absolute;
}

.flipbook-page3-bg {
    width: 100%;
    height: 100%;
    position: absolute;
}

.flipbook-page3-image {
    /*overflow: hidden;*/
    width: 100%;
    height: 100%;
    position: absolute;
    /*transform: translateZ(0);*/
    /* -webkit-transform: translateZ(0); */
}

.flipbook-page3 img {
    /*transform-origin:0 0;
    -webkit-transform-origin:0 0;
    transform:translateZ(0);
    -webkit-transform:translateZ(0);*/
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    /* width:  100%; */
    height: 100%;
    margin: auto;
    position: absolute;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    /* transform:translateZ(0); */
    /* -webkit-transform:translateZ(0); */
    float: left;
    user-select: none;
    pointer-events: none;
    max-width: unset;
}

.flipbook-page3 canvas {
    /* position:absolute; */
    width: 100%;
    height: 100%;
    /* top: 0; */
    /* left: 0; */
    /*transform: translateZ(0);*/
    /*backface-visibility: hidden;*/
    /* float: left; */
}

.flipbook-page3-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    /*transform: translateZ(0);*/
    /*-webkit-transform: translateZ(0);*/
    /*background:rgba(0,0,0,.6);*/
    background: #000;
    opacity: 0;
    pointer-events: none;
    /*opacity:.5;*/
}

.flipbook-page3-front {
    transform-origin: 0 50%;
    -webkit-transform-origin: 0 50%;
   /* transform: translateZ(0);
    -webkit-transform: translateZ(0);*/
}

.flipbook-page3-back {
    transform-origin: 100% 50%;
    -webkit-transform-origin: 100% 50%;
    /*transform: translateZ(0);
    -webkit-transform: translateZ(0);*/
    z-index: 999999 !important;
}

.flipbook-book3 {
    display: block;
    position: relative;
    /*background: #F00;*/
}

.flipbook-center-container3 {
    position: relative;
    /* height:100%; */
    perspective: 4000px;
    -webkit-perspective: 4000px;
    /*transform: translateZ(0px);*/
    /*-webkit-transform:translateZ(0px);*/
    will-change: transform;
    border: none;
    /* background: none; */
    /* color:  #000; */
    /* opacity: .1; */
    /* box-shadow:  none; */
    /* overflow:  hidden; */
}

.flipbook-page-face {
    position: absolute;
    background-color: #DDD;
/*    transform: translateZ(0px);
    -webkit-transform: translateZ(0px);*/
    -backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.flipbook-page-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .9);
    pointer-events: none !important;
    z-index: 10;
}

.flipbook-page-htmlContent {
    transform-origin: 0 0;
    z-index: 50;
}

.flipbook-page-htmlContent video, .flipbook-page-htmlContent iframe{
    max-width: initial;
}

.flipbook-book canvas {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
/*    transform: translateZ(0px);
    -webkit-transform: translateZ(0px);*/
    -backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.flipbook-page img {
    width: 100%;
    height: 100%;
/*    transform: translateZ(0px);
    -webkit-transform: translateZ(0px);*/
    -backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
}

.flipbook-opacity0 {
    opacity: 0;
}

.flipbook-opacity1 {
    opacity: 1;
}

.flipbook-arrow-wrapper {
    opacity: .85;
    transition: opacity .3s;
}

.flipbook-arrow-wrapper:hover {
    opacity: 1;
}

.flipbook-left-arrow {
    display: block !important;
    position: absolute !important;
    top: 50% !important;
    margin-top: -20px;
    speak: none;
    font-style: normal;
    font-weight: normal;
    /* font-size: 32px !important; */
    /* line-height: 40px !important; */
    -webkit-font-smoothing: antialiased;
    text-align: center;
    cursor: pointer;
    width: 40px;
    /* height: 40px; */
    -webkit-border-radius: 0;
    border-radius: 0;
}

.flipbook-right-arrow {
    display: block !important;
    position: absolute !important;
    top: 50% !important;
    speak: none;
    right: 0;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    cursor: pointer;
}

.flipbook-first-arrow {
    display: block !important;
    position: absolute !important;
    top: 50%;
    speak: none;
    left: 0;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    cursor: pointer;
}

.flipbook-last-arrow {
    display: block !important;
    position: absolute !important;
    top: 50%;
    speak: none;
    right: 0;
    font-style: normal;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    cursor: pointer;
}

.flipbook-currentPageHolder {
    position: relative;
    z-index: 3;
    pointer-events: auto;
    display: inline-block;
    user-select: none;
}

.flipbook-floating{
    background:rgba(0,0,0,.3);
    color: #FFF;
    position: absolute;
}

.flipbook-floating span{
    color: #FFF;
}

.flipbook-floating span:hover{
    color: #FFF;
}

.flipbook-currentPageHolder form{
    position: absolute !important;
    top: 0;
}

.flipbook-currentPageNumber {
    /* font-weight: bold !important; */
    border-radius: 15px !important;
    /*     color: rgba(255, 255, 255, 0.72); */
    padding: 5px 12px !important;
    /*     background-color: rgba(0, 0, 0, 0.4); */
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
    font-size: 14px;
    line-height: initial;
}




/*.flipbook-menu-title {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
    font-size: 16px;
    text-align: center;
    display: block;
    padding: 10px 0;
    width: 100%;
}*/

.flipbook-currentPageInput {
    padding: initial !important;
    position: absolute !important;
    line-height: 14px !important;
    background: none !important;
    border: none !important;
    font-size: 14px !important;
    max-width: initial !important;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
    /* font-weight: bold !important; */
    text-align: center !important;
    /* color: rgba(255, 255, 255, 0.72) !important; */
    height: 26px !important;
    top: 0 !Important;
    left: 0 !important;
    border: 2px solid rgba(128,128,128,.5);
}

.flipbook-currentPageInput:focus {
    /* outline: 0 !important; */
    outline: 2px solid #88888885;
}

.flipbook-color-transparent {
    color: transparent!important;
}

.flipbook-browser-fullscreen {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 999999 !important;
    margin: 0 !important;
    padding: 0 !important;
}




/*
.flipbook-totalPages {
    background: none;  box-shadow: none;
    position: absolute !important;
    padding-left: 3em !important;
    border: none !important;
    width: 3em !important;
    
    font-size: 1.2em !important;
    font-family: 'Open Sans','Helvetica Neue', Arial, sans-serif;
    
    text-align: left !important;
    line-height: 30px !important;
    
    z-index: 9999;
    height: 35px;
}*/

.flipbook-totalPages {
    box-shadow: none;
    padding-left: 3em !important;
    padding-right: 1em !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border: none !important;
    width: 3em !important;
    font-size: 1em !important;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    text-align: left !important;
    line-height: 30px !important;
    /*z-index: 9999;*/
    height: 35px;
    /* opacity: .7 !important; */
    pointer-events: none;
}

.flipbook-currentPage:focus {
    outline: none;
}

.flipbook-menuBottom {
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0;
    z-index: 2;
    pointer-events: none;
    display:flex;
    /* justify-content: space-between; */
}

.flipbook-menu-fixed{
    left: 0;
    right: 0;
}

.flipbook-menu-fixed .flipbook-menu{
    align-items: center;
}

.flipbook-menu-center{
    justify-content:center;
    align-items: center;
    /* margin-left: auto; */
}

.flipbook-menu-right{
    justify-content: flex-end;
    align-self: center;
    flex: 1;
  display: flex;
  min-width: -webkit-min-content; /* Workaround to Chrome bug */
    /* margin-left: auto; */
}

.flipbook-menu-left{
    justify-content: flex-start;
    align-self: center;
    flex: 1;
  display: flex;
  min-width: -webkit-min-content; /* Workaround to Chrome bug */
}



.flipbook-menu-floating{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: fit-content;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    border-radius: 2px;
}

.flipbook-menuTop {
    position: absolute;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 2;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
}

.flipbook-menuWrapper:hover {
    opacity: 1;
}

.flipbook-menu {
    position: relative;
    text-align: center;
    display: flex;
}

.flipbook-menu-btn {
    user-select: none;
    display: inline;
    cursor: pointer;
    background: none;
    transition: all .1s ease-out;
    -webkit-transition: all .1s ease-out;
    -moz-transition: all .1s ease-out;
    -o-transition: all .1s ease-out;
}

.flipbook-thumbHolder {
    bottom: 0;
    position: absolute;
    top: 0;
    z-index: 9;
    width: 250px;
}


.flipbook-thumbsWrapper {
    bottom: 0;
    position: absolute;
    left: 0;
    top: 90px;
    overflow: hidden;
    padding-top: 20px;
}

.flipbook-thumbsScroller {
    margin: 0;
    padding: 0;
    position: relative;
    text-align: center;
    min-width: 250px;
    padding-bottom: 20px;
    display: grid;
}

.flipbook-thumb {
    margin: 0px auto 30px;
    cursor: pointer;
    position: relative;
    background: rgba(255,255,255,.1);
    border: 1px solid rgba(116, 116, 116, .3);
}

.flipbook-search{
    box-shadow: rgba(0, 0, 0, .2) 0px 5px 5px;
    padding-bottom: 7px;
}

.flipbook-search-match{
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.flipbook-search-match:hover{
    text-decoration: underline;
}

.flipbook-search-match-title{
    height: 20px;
}

.flipbook-search-match-text{
    text-align: left;
}

.flipbook-empty-thumb {
    display: inline-block;
    position: absolute;
    background: rgb(255, 0, 0);
}

.flipbook-thumb-num {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
    /* font-weight: bold; */
    line-height: 14px;
    user-select: none;
    /* background: rgba(0, 0, 0, 0.39); */
    /* padding: 10px; */
    position: relative;
    top: 5px;
}

.flipbook-thumb img {
    /* border: 1px solid rgba(134, 134, 134, 0.33); */
    display: inline-block;
    /* margin: 0 2px 7px 0; */
    -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    /* -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2); */
    /* box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2); */
}

.flipbook-toc {
    position: relative;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50px;
    bottom: 0;
    overflow:  hidden;
    width: 100%;
}

.flipbook-tocHolder {
    position: absolute;
    top: 0;
    width: 250px;
    bottom: 0;
    z-index: 9;
}

.flipbook-tocItem {
    display: block;
    /* padding: 10px 20px; */
    /* text-align: justify; */
    cursor: pointer;
    font-size: 14px;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    /* font-family: "Roboto Condensed", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", sans-serif;  font-weight: 300;*/
    /*     border-top: solid 1px rgba(135, 135, 135, 0.22); */
    /* border-bottom: solid 1px rgba(0,0,0,.3); */
}

.flipbook-tocItem .title{
    display: inline-block;
    /* width: 170px; */
}

.flipbook-tocItem .title:hover{
    text-decoration: underline;
}

.flipbook-tocItem:hover {
/*    background: rgba(128, 128, 128, .2) */
}

.flipbook-tocItem .right {
    float: right;
}

.flipbook-tocTitle {
    display: block;
    padding: 20px 0;
    text-align: right;
    text-transform: uppercase;
}

.flipbook-bmHolder {
    position: absolute;
    top: 0;
    width: 250px;
    bottom: 0;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    /* Chrome 1-25, Safari 3.2+ */
    -moz-transition: all .3s ease-out;
    /* Firefox 4-15 */
    -o-transition: all .3s ease-out;
    /* Opera 10.50
    transition: all .3s ease-out;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
    /* margin: 20px; */
    /* pointer-events: none; */
    /* background: #000; */
    z-index: 9;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16), 0 0 6px rgba(0, 0, 0, 0.23);
}

.flipbook-searchHolder {
    position: absolute;
    top: 0;
    width: 250px;
    bottom: 0;
    transition:  all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    /* Chrome 1-25, Safari 3.2+ */
    -moz-transition: all .3s ease-out;
    /* Firefox 4-15 */
    -o-transition: all .3s ease-out;
    /* Opera 10.50
    transition: all .3s ease-out;  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
    /* margin: 20px; */
    /* pointer-events: none; */
    /* background: #000; */
    z-index: 9;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16), 0 0 6px rgba(0, 0, 0, 0.23);
}



.flipbook-findbar input {
    width: 210px;
    padding: 5px;
    font-size: 14px;
    margin-left: 10px;
}

.flipbook-findbar input:focus {
    outline:2px solid #bfbfbf;
}

.flipbook-find-info {
    padding-left: 15px;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
    font-size: 14px;
    pointer-events: none;
}


.invisible {
    opacity: 0;
    pointer-events: none;
    display: none;
}

.transition {
    -webkit-transition: all .3s ease-out;
    /* Chrome 1-25, Safari 3.2+ */
    -moz-transition: all .3s ease-out;
    /* Firefox 4-15 */
    -o-transition: all .3s ease-out;
    /* Opera 10.50*/
    transition: all .3s ease-out;
    /* Chrome 26, Firefox 16+, IE 10+, Opera 12.50+ */
}

.flipbook-shareButtons {
    width: 41px;
    position: absolute;
    /*right: 0;*/
    /*top: 0;*/
    bottom: 40px;
    /*-webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .5);*/
    /*box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, .5);*/
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    /* Aligns Vertically - Remove for Horizontal Only */
    /* Aligns Horizontally - Remove for Vertical Only  */
    ;
    /* padding: 10px 0; */
    /*margin-right: 10px;*/
    text-align: center;
}

.flipbook-shareBtn {
    display: inline-block;
    cursor: pointer;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
}

.flipbook-share-email{
    background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='at' class='svg-inline--fa fa-at fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M256 8C118.941 8 8 118.919 8 256c0 137.059 110.919 248 248 248 48.154 0 95.342-14.14 135.408-40.223 12.005-7.815 14.625-24.288 5.552-35.372l-10.177-12.433c-7.671-9.371-21.179-11.667-31.373-5.129C325.92 429.757 291.314 440 256 440c-101.458 0-184-82.542-184-184S154.542 72 256 72c100.139 0 184 57.619 184 160 0 38.786-21.093 79.742-58.17 83.693-17.349-.454-16.91-12.857-13.476-30.024l23.433-121.11C394.653 149.75 383.308 136 368.225 136h-44.981a13.518 13.518 0 0 0-13.432 11.993l-.01.092c-14.697-17.901-40.448-21.775-59.971-21.775-74.58 0-137.831 62.234-137.831 151.46 0 65.303 36.785 105.87 96 105.87 26.984 0 57.369-15.637 74.991-38.333 9.522 34.104 40.613 34.103 70.71 34.103C462.609 379.41 504 307.798 504 232 504 95.653 394.023 8 256 8zm-21.68 304.43c-22.249 0-36.07-15.623-36.07-40.771 0-44.993 30.779-72.729 58.63-72.729 22.292 0 35.601 15.241 35.601 40.77 0 45.061-33.875 72.73-58.161 72.73z'%3E%3C/path%3E%3C/svg%3E");
}

.flipbook-menu-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 999999;
}




/* img{
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
} */

.flipbook-icon{
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
/*     padding-bottom: 12px;
    padding-top: 12px;
    width: 36px; */
    bottom: 0;
    text-align: center;
    pointer-events: auto;
    box-sizing: initial;
    display: inline-block;
}

.flipbook-btn-close {
    height: 40px;
    width: 40px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}


.flipbook-btn-close-icon {
    height: 40px;
    line-height: 40px !important;
}


/* ui */

.flipbook-skin-color {
    /*     -webkit-transition: all .3s ease-out;
    -moz-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .3s ease-out; */
}

.flipbook-font {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;
}

.flipbook-btn {
    text-transform: uppercase;
    font-weight: bold;
    user-select: none;
    cursor: pointer;
    /* padding: 5px 25px; */
    width: 200px;
    line-height: 32px;
    text-align: center;
    /* background: rgba(0,0,0,.1); */
    margin: 5px auto;
    border: 1px solid #868686;
    border: none;
    background: #f9f9f9;
    background: none;
    border-radius: 3px;
    /* border-bottom: 3px solid rgba(0, 0, 0, .15); */
    /* border: 2px solid #999; */
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    font-size: 12px;
}




/*dark*/

.flipbook-bg-dark {
    background: rgb(49, 53, 56);
}

.flipbook-color-dark {
    color: rgba(255, 255, 255, 0.75);
}

.flipbook-color-dark:hover {
    color: #FFF;
}




/*light*/

.flipbook-bg-light {
    background: rgb(255, 255, 255);
}

.flipbook-color-light {
    color: rgba(50, 50, 50, 0.75);
}

.flipbook-color-light:hover {
    color: rgb(50,50,50);
}




/*twilight*/

.flipbook-bg-twilight {
    background: #141414;
}

.flipbook-color-twilight {
    color: #FEFFD3;
}

.flipbook-color-twilight:hover {
    color: #FEFFD3;
}




/*darkgrey*/

.flipbook-bg-darkgrey {
    background: #212121;
}

.flipbook-color-darkgrey {
    color: #9E9E9E;
}

.flipbook-color-darkgrey:hover {
    color: #BDBDBD;
}




/*lightgrey*/

.flipbook-bg-lightgrey {
    background: #E0E0E0;
}

.flipbook-color-lightgrey {
    color: #757575;
}

.flipbook-color-lightgrey:hover {
    color: #616161;
}

.flipbook-lightbox-toolbar {
    /* opacity:.6; */
}

.flipbook-lightbox-close {
    position: absolute !important;
    z-index: 85 !important;
    right: 0px !important;
    top: 0 !important;
    text-align: center !important;
    text-decoration: none !important;
    cursor: pointer;
    opacity: .85;
    transition: opacity .3s;
}

.flipbook-lightbox-close:hover {
    opacity: 1;
}

.flipbook-loading-bar {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: #333;
    border-bottom: 1px solid #333;
}

.flipbook-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ddd;
    overflow: hidden;
    -webkit-transition: width 200ms;
    transition: width 200ms;
}

.flipbook-sub-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 999;
    display: table;
}

.flipbook-sub-menu-center {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}


.flipbook-menu-header {
    position: relative;
    padding: 10px;
    /* padding-bottom: 20px; */
    font-size: 16px;
    text-align: center;
    /* background: rgba(128,128,128,.2); */
    /* border-bottom: 1px solid rgba(128,128,128,.5); */
}

.flipbook-menu-title {
    pointer-events: none;
    user-select: none;
    /* opacity: .9; */
}

.flipbook-sub-menu-content {
    display: inline-block;
    margin-top: -20px;
    min-width: 250px;
    padding-bottom: 10px;
}

.flipbook-carousel-scroller {
    /* height:100%; */
    /* float:left; */
    /* padding:0; */
    /* position: absolute; */
    /* top:0; */
}

.flipbook-carousel-slide {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    overflow: hidden;
}

.flipbook-carousel-slide .slide-inner {
    /* height: 100%; */
    /* box-shadow: 10px 10px 50px rgb(50, 50, 50); */
}

.flipbook-carousel-slide .slide-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.flipbook-carousel-slide canvas {}

.flipbook-carousel-page {
    /* height:100%; */
    /* margin: 0 auto; */
    display: inline-block;
    background: #f1f1f1;
    position: relative;
    /* float: left; */
    /* opacity: .05; */
    /* -webkit-transform-origin: 0 0; */
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    /* transform-origin:0 0; */
    /* width: 100%; */
    /* height: 100%; */
    /* box-shadow: 0px 0px 10px #f00; */
}

.flipbook-carousel-page-inner {
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    /* background: #f0f0f0; */
    margin: 0;
    padding: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.flipbook-carousel-page-inner canvas {
    width: 100%;
    height: 100%;
    /*transform: translateZ(0);*/
}

.flipbook-carousel-zoom-page {
    height: 100%;
    position: relative;
}

.flipbook-carousel-zoom-page img {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    position: absolute;
}

.flipbook-carousel-page img {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    position: absolute;
    pointer-events: none;
}

.flipbook-carousel-page .page-carousel-img {
    height: 100%;
    /* width: 100%; */
    /*transform: translateZ(0);*/
}

.flipbook-carousel-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.flipbook-carousel-zoom-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
}

.flipbook-carousel-zoom {
    position: relative;
    width: 100%;
    height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.flipbook-overflow-hidden::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  
  .flipbook-overflow-hidden {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }




/*share*/

.flipbook-share .social.bottom,
.flipbook-share .social.top {
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0
}

.flipbook-share {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.flipbook-share [class*=entypo-]:before {
    font-family: entypo, sans-serif
}

.flipbook-share label {
    font-size: 16px;
    cursor: pointer;
    margin: 0;
    padding: 5px 10px;
    border-radius: 5px;
    background: #a29baa;
    color: #333;
    transition: all .3s ease
}

.flipbook-share label:hover {
    opacity: .8
}

.flipbook-share label span {
    text-transform: uppercase;
    font-size: .9em;
    font-family: Lato, sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    padding-left: 6px
}


.flipbook-share ul {
    position: relative;
    left: 0;
    right: 0;
    height: 46px;
    color: #fff;
    margin: auto;
    padding: 0;
    list-style: none
}

.flipbook-share ul li {
    font-size: 16px;
    cursor: pointer;
    width: 40px;
    margin: 0;
    padding: 9px 0;
    text-align: center;
    float: left;
    /* display: none; */
    height: 22px;
    position: relative;
    z-index: 2;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    transition: all .3s ease;
}

.flipbook-page-preloader {
    width: auto !important;
    height:  auto !important;
}

.flipbook-page-preloader-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


/** preloader*/

.cssload-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    position: absolute;
    z-index: 2147483647!important;
    pointer-events: none;
}

.cssload-speeding-wheel {
    width: 24px;
    height: 24px;
    /* margin: 0 auto; */
    border: 3px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    border-left-color: rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.2);
    animation: cssload-spin 575ms infinite linear;
    -o-animation: cssload-spin 575ms infinite linear;
    -ms-animation: cssload-spin 575ms infinite linear;
    -webkit-animation: cssload-spin 575ms infinite linear;
    -moz-animation: cssload-spin 575ms infinite linear;
    top: 45%;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    z-index: 3;
}

@keyframes cssload-spin {
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-spin {
    100% {
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-spin {
    100% {
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-spin {
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}




/**new preloader end*/

.flipbook-lightbox-preloader {
    background: rgba(0, 0, 0, .5);
    height: 100%;
    width: 100%;
}

.flipbook-loading-text {
    position: absolute;
    top: 45%;
    margin-top: 20px;
    width: 100%;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif !important;
    font-size: 14px;
    color: #AAA !important;
}

.flipbook-loading-bg {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    top: 45%;
    left: 50%;
    position: absolute;
    pointer-events: none;
    background: rgba(0, 0, 0, .2);
    margin: -20px;
    display: none;
}

.flipbook-fillPreloader {
    position: fixed;
    width: 100%;
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    pointer-events: none;
}

.flipbook-fillPreloader img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    -webkit-transition: all 0.11s ease-out;
    -moz-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}


.thumb-btn-close {
    right: -15px;
    position: absolute;
    top: 0;
    cursor: pointer;
}


.flipbook-noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.flipbook-btn-active {
    background: rgba(128, 128, 128, .5);
    border: 2px solid rgba(128, 128, 128, .5) inset;
}

.flipbook-move {
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.flipbook-page-item{
    bottom : auto;
    right: auto;
    position: absolute;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.flipbook-page-item iframe{
    width: 100%;
    height: 100%;
}




/* Copyright 2014 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.flipbook-textLayer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: var(--flipbook-link-opacity);
    line-height: 1.0;
    color: #000;
}

.flipbook-page-htmlContent *{
    max-width: initial;
}

.flipbook-textLayer mark {
    background: #ff0;
    color: transparent;
}

.flipbook-textLayer>span {
    color: transparent !important;
    /*     color: #f00; */
    position: absolute;
    white-space: pre;
    cursor: text;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
}

.flipbook-textLayer>div {
    color: transparent !important;
    /*     color: #f00; */
    position: absolute;
    white-space: pre;
    cursor: text;
    -webkit-transform-origin: 0% 0%;
    -moz-transform-origin: 0% 0%;
    -o-transform-origin: 0% 0%;
    -ms-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
}

.flipbook-textLayer .highlight {
    margin: -1px;
    padding: 1px;
    background-color: rgb(180, 0, 170);
    border-radius: 4px;
}

.flipbook-textLayer .highlight.begin {
    border-radius: 4px 0px 0px 4px;
}

.flipbook-textLayer .highlight.end {
    border-radius: 0px 4px 4px 0px;
}

.flipbook-textLayer .highlight.middle {
    border-radius: 0px;
}

.flipbook-textLayer .highlight.selected {
    background-color: rgb(0, 100, 0);
}

.flipbook-textLayer ::selection {
    background: rgb(0, 0, 255);
}

.flipbook-textLayer ::-moz-selection {
    background: rgb(0, 0, 255);
}

.flipbook-textLayer .endOfContent {
    background: initial;  /*fix for overriding bacckground color with class .active*/
    display: block;
    position: absolute;
    left: 0px;
    top: 100%;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    cursor: default;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
}

.flipbook-textLayer .endOfContent.active {
    top: 0px;
}


.flipbook-page-simple{
    /* position:absolute; */
    display:  inline-block;
}
.flipbook-page-simple-inner{
    position:absolute;
}

/*.flipbook-page-simple-inner img{
    transform:translateZ(0);
}

.flipbook-page-simple-inner canvas{
    transform:translateZ(0);
}*/

.flipbook-book-simple{
    text-align:center;
}

.flipbook-disable-text-selection{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.annotationLayer section {
  position: absolute;
  padding: 0;
  margin: 0;
}

.annotationLayer .linkAnnotation > a,
.annotationLayer .buttonWidgetAnnotation.pushButton > a,
.flipbook-page-item-link {
  position: absolute;
  font-size: 1em;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--flipbook-link-color);
  animation-duration: 2000ms;
  animation-delay: 500ms;
  animation-name: flashLinks;
}

.flipbook-page-auto-link{
    background: var(--flipbook-link-color);
    color: transparent;
    animation-duration: 2000ms;
    animation-delay: 500ms;
    animation-name: flashLinks;
    pointer-events: auto;

}

@keyframes flashLinks {
   0% {
      background: var(--flipbook-link-color);
   }
   20% {
      background: var(--flipbook-link-color-hover);
   }
   100% {
      background: var(--flipbook-link-color);
   }
 }

.annotationLayer .linkAnnotation > a:hover,
.annotationLayer .buttonWidgetAnnotation.pushButton > a:hover,
.flipbook-page-item-link:hover {
  background: var(--flipbook-link-color-hover) !important;
  color: transparent !important;
}

.flipbook-page-auto-link:hover {
    background: var(--flipbook-link-color-hover) !important;
    color: transparent !important;
  }

.annotationLayer .textAnnotation img {
  position: absolute;
  cursor: pointer;
}

.annotationLayer .textWidgetAnnotation input,
.annotationLayer .textWidgetAnnotation textarea,
.annotationLayer .choiceWidgetAnnotation select,
.annotationLayer .buttonWidgetAnnotation.checkBox input,
.annotationLayer .buttonWidgetAnnotation.radioButton input {
  background-color: rgba(0, 54, 255, 0.13);
  border: 1px solid transparent;
  box-sizing: border-box;
  font-size: 9px;
  height: 100%;
  margin: 0;
  padding: 0 3px;
  vertical-align: top;
  width: 100%;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  font: message-box;
  font-size: 9px;
  resize: none;
}

.annotationLayer .textWidgetAnnotation input[disabled],
.annotationLayer .textWidgetAnnotation textarea[disabled],
.annotationLayer .choiceWidgetAnnotation select[disabled],
.annotationLayer .buttonWidgetAnnotation.checkBox input[disabled],
.annotationLayer .buttonWidgetAnnotation.radioButton input[disabled] {
  background: none;
  border: 1px solid transparent;
  cursor: not-allowed;
}

.annotationLayer .textWidgetAnnotation input:hover,
.annotationLayer .textWidgetAnnotation textarea:hover,
.annotationLayer .choiceWidgetAnnotation select:hover,
.annotationLayer .buttonWidgetAnnotation.checkBox input:hover,
.annotationLayer .buttonWidgetAnnotation.radioButton input:hover {
  border: 1px solid #000;
}

.annotationLayer .textWidgetAnnotation input:focus,
.annotationLayer .textWidgetAnnotation textarea:focus,
.annotationLayer .choiceWidgetAnnotation select:focus {
  background: none;
  border: 1px solid transparent;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after,
.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  background-color: #000;
  content: '';
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before,
.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  height: 80%;
  left: 45%;
  width: 1px;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  border-radius: 50%;
  height: 50%;
  left: 30%;
  top: 20%;
  width: 50%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  font-family: monospace;
  padding-left: 2px;
  padding-right: 0;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  /*
   * Letter spacing is placed on the right side of each character. Hence, the
   * letter spacing of the last character may be placed outside the visible
   * area, causing horizontal scrolling. We avoid this by extending the width
   * when the element has focus and revert this when it loses focus.
   */
  width: 115%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input,
.annotationLayer .buttonWidgetAnnotation.radioButton input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0;
}

.annotationLayer .popupWrapper {
  position: absolute;
  width: 20em;
}

.annotationLayer .popup {
  position: absolute;
  z-index: 200;
  max-width: 20em;
  background-color: #FFFF99;
  box-shadow: 0px 2px 5px #333;
  border-radius: 2px;
  padding: 0.6em;
  margin-left: 5px;
  cursor: pointer;
  font: message-box;
  word-wrap: break-word;
}

.annotationLayer .popup h1 {
  font-size: 1em;
  border-bottom: 1px solid #000000;
  margin: 0;
  padding-bottom: 0.2em;
}

.annotationLayer .popup p {
  margin: 0;
  padding-top: 0.2em;
}

.annotationLayer .highlightAnnotation,
.annotationLayer .underlineAnnotation,
.annotationLayer .squigglyAnnotation,
.annotationLayer .strikeoutAnnotation,
.annotationLayer .lineAnnotation svg line,
.annotationLayer .squareAnnotation svg rect,
.annotationLayer .circleAnnotation svg ellipse,
.annotationLayer .polylineAnnotation svg polyline,
.annotationLayer .polygonAnnotation svg polygon,
.annotationLayer .inkAnnotation svg polyline,
.annotationLayer .stampAnnotation,
.annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

/* print.js https://github.com/crabbly/Print.js/releases/tag/v1.5.0 */
.printModal{font-family:sans-serif;display:flex;text-align:center;font-weight:300;font-size:30px;left:0;top:0;position:absolute;color:#045fb4;width:100%;height:100%;background-color:hsla(0,0%,100%,.9)}.printClose{position:absolute;right:10px;top:10px}.printClose:before{content:"\00D7";font-family:Helvetica Neue,sans-serif;font-weight:100;line-height:1px;padding-top:.5em;display:block;font-size:2em;text-indent:1px;overflow:hidden;height:1.25em;width:1.25em;text-align:center;cursor:pointer}.printSpinner{margin-top:3px;margin-left:-40px;position:absolute;display:inline-block;width:25px;height:25px;border:2px solid #045fb4;border-radius:50%;animation:spin .75s linear infinite}.printSpinner:after,.printSpinner:before{left:-2px;top:-2px;display:none;position:absolute;content:"";width:inherit;height:inherit;border:inherit;border-radius:inherit}.printSpinner,.printSpinner:after,.printSpinner:before{display:inline-block;border-color:#045fb4 transparent transparent;animation-duration:1.2s}.printSpinner:before{transform:rotate(120deg)}.printSpinner:after{transform:rotate(240deg)}@keyframes spin{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}